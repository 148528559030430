import Cookies from 'js-cookie';
import {
  AUTHENTICATE,
  AUTHENTICATE_WITH_2FA,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_IN_PROGRESS,
  AUTHENTICATION_WITH_2FA_IN_PROGRESS,
  AuthErrorObject,
  AuthenticationAction,
  CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS,
  IAuthenticate,
  IAuthenticateWith2FA,
  IAuthenticationInProgress,
  IAuthenticationWith2FAInProgress,
  ICancel2FAInProgress,
  IUnauthenticate,
  TWO_FACTOR_AUTHENTICATION_FAILED,
  UNAUTHENTICATE,
  UserDetails,
  UserDetails2FAWithTokens,
  VerficationDetails,
} from '../../types/redux/auth/authTypes';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import requestClient from '../../utilities/requestClient';
import jwt_decode from 'jwt-decode';
import FastAPIAuthService from '../../services/risksystem3-public-auth-service';
import TokenService from '../../services/token-service';

// ------------------ Common Code ------------------ //

const handleLoginSuccess = async (
  dispatch: Dispatch<AuthenticationAction, {}, any>,
  response: any,
  loginResponse: any,
) => {
  window.localStorage.setItem('authenticated', 'true');
  
  const regex = /^https:\/\/dev2\w*\.risksystem\.com.*/;
  
  if (process.env.NODE_ENV === 'development') {
    Cookies.set('user_id', 'development');
  } else if (regex.test(window.location.href)) {
    const domain = new URL(window.location.href).hostname;
    Cookies.set('dev_user_id', 'development', {
      domain,
      path: '/',
      secure: true,
      sameSite: 'None',
      expires: 30
    });
  }

  TokenService.setAccessToken(loginResponse.data.access_token);

  dispatch(authenticate(
    response.data.user_name,
    loginResponse.data.client_name,
    loginResponse.data.config_name
  ));
};

const handleLoginError = (
  dispatch: Dispatch<AuthenticationAction, {}, any>,
  error: any,
  detail: string
) => {
  console.error(detail, error);
  FastAPIAuthService.FastAPILogout();
  Cookies.remove('user_id');
  dispatch(authenticationFailed({
    detail: detail
  }));
};

// ------------------ General Authentication Actions ------------------ //

export function unauthenticate(): IUnauthenticate {
  return {
    type: UNAUTHENTICATE,
  };
}

export function authenticate(
  user_id: string,
  client_name: string,
  config_name: string,
): IAuthenticate {
  return {
    type: AUTHENTICATE,
    user_id: user_id,
    client_name: client_name,
    config_name: config_name,
  };
}

export function authenticationInProgress(): IAuthenticationInProgress {
  return {
    type: AUTHENTICATION_IN_PROGRESS,
  };
}

export function authenticationFailed(errorMessage: AuthErrorObject) {
  return {
    type: AUTHENTICATION_FAILED,
    payload: errorMessage,
  };
}

export function logOut() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    FastAPIAuthService.FastAPILogout();
    Cookies.remove('user_id');
    localStorage.clear();
    dispatch({ type: 'USER_LOGOUT' });
    const client = requestClient();
    await client.get('/logout');
  };
}

export function logIn(userDetails: UserDetails) {
  return (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // Set Authentication In Progress
    dispatch(authenticationInProgress());

    const formData = new FormData();
    formData.append('username', userDetails.username);
    formData.append('password', userDetails.password);
    formData.append('grant_type', 'password');

    client
      .post('/api_token', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Basic Og==',
        },
      })
      .then((loginResponse) => {
        const decodedToken: any = jwt_decode(loginResponse.data.access_token);
        if (loginResponse.data.login_return_code === 0) {
          // this is the case that login is ok

          FastAPIAuthService.FastAPIlogin(userDetails.username, userDetails.password)
            .then((response: any) => {
              handleLoginSuccess(dispatch, response, loginResponse);
            })
            .catch((err) => {
              handleLoginError(dispatch, err, 'Error retrieving user details');
            });

        } else if (loginResponse.data.login_return_code === 3) {
          // this is the case where the user needs to use 2FA
          // this is wholly out of date with current status of the app, and would need to be redesigned
          const accessToken = loginResponse.data.access_token;
          const authKey = loginResponse.data.auth_key;

          // case where 2FA is going to be required.
          // Check what kind of authentication Is required.
          if (decodedToken.is_email_needed) {
            // Request the verification code email.
            dispatch(
              requestVerficationCodeEmail({
                username: userDetails.username,
                accessToken: accessToken,
                authKey: authKey,
                isQrCodeRequired: decodedToken.is_qr_code_needed || false,
                isEmailTokenRequired: decodedToken.is_email_needed,
              }),
            );
          } else if (decodedToken.is_qr_code_needed) {
            // In this case skip straight to the login screen.
            dispatch(
              authenticateWith2FA(
                userDetails.username,
                loginResponse.data.client_name,
                loginResponse.data.config_name,
                accessToken,
                authKey,
                false,
                decodedToken.is_email_needed,
                decodedToken.is_qr_code_needed,
              ),
            );
          } else {
            authenticationFailed({ detail: 'There was an error logging in.' });
          }
        } else {
          dispatch(unauthenticate());
        }
      })
      .catch((err) => {
        const errorStatus = err.response ? err.response.status : null;
        const errorData = err.response ? err.response.data : null;

        if (errorStatus === 401 || errorStatus === 403) {
          dispatch(authenticationFailed(errorData));
        } else {
          dispatch(
            authenticationFailed({ detail: 'There was an error logging in.' }),
          );
        }
      });
  };
}

// ------------------ 2FA Authentication Actions ------------------ //

export function authenticationWith2FAInProgress(
  emailTokenRequired: boolean,
  qrCodeRequired: boolean,
  user_id: string,
  client_name: string,
  config_name: string,
  accessToken: string,
  authKey: string,
): IAuthenticationWith2FAInProgress {
  return {
    type: AUTHENTICATION_WITH_2FA_IN_PROGRESS,
    qrCodeRequired: qrCodeRequired,
    emailTokenRequired: emailTokenRequired,
    user_id: user_id,
    client_name: client_name,
    config_name: config_name,
    accessToken: accessToken,
    authKey: authKey,
  };
}

export function cancel2FALogin(): ICancel2FAInProgress {
  return {
    type: CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS,
  };
}

export function authenticateWith2FA(
  user_id: string,
  client_name: string,
  config_name: string,
  accessToken: string,
  authKey: string,
  emailSent: boolean,
  emailTokenRequired: boolean,
  qrCodeRequired: boolean,
): IAuthenticateWith2FA {
  return {
    type: AUTHENTICATE_WITH_2FA,
    user_id: user_id,
    client_name: client_name,
    config_name: config_name,
    accessToken: accessToken,
    authKey: authKey,
    qrCodeRequired: qrCodeRequired,
    emailTokenRequired: emailTokenRequired,
    emailSent: emailSent,
  };
}

export function twoFactorAuthenticationFailed(
  errorMessage: AuthErrorObject,
  user_id: string,
  accessToken: string,
  authKey: string,
  emailSent: boolean,
  emailTokenRequired: boolean,
  qrCodeRequired: boolean,
) {
  return {
    type: TWO_FACTOR_AUTHENTICATION_FAILED,
    errorMessage: errorMessage,
    user_id: user_id,
    accessToken: accessToken,
    authKey: authKey,
    emailSent: emailSent,
    emailTokenRequired: emailTokenRequired,
    qrCodeRequired: qrCodeRequired,
  };
}

export function requestVerficationCodeEmail(
  verficiationDetails: VerficationDetails,
) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // Set authentication in progress.
    dispatch(
      authenticationWith2FAInProgress(
        verficiationDetails.isEmailTokenRequired,
        verficiationDetails.isQrCodeRequired,
        verficiationDetails.username,
        '',
        '',
        verficiationDetails.accessToken,
        verficiationDetails.authKey,
      ),
    );

    // Create an object of formData
    const formData = new FormData();
    formData.append('auth_key', verficiationDetails.authKey);

    client
      .post('/api/v1/get_verification_code?auth_type=user_email', formData, {
        headers: {
          Accept: 'application/json',
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${verficiationDetails.accessToken}`,
        },
      })
      .then((qr_response) => {
        if (qr_response.data.status === 200) {
          dispatch(
            authenticateWith2FA(
              verficiationDetails.username,
              '',
              '',
              verficiationDetails.accessToken,
              verficiationDetails.authKey,
              true,
              verficiationDetails.isEmailTokenRequired,
              verficiationDetails.isQrCodeRequired,
            ),
          );
        }
      })
      .catch((err) => {
        dispatch(
          twoFactorAuthenticationFailed(
            {
              detail:
                'An error occured while attempting to send the verification code to your email.',
            },
            verficiationDetails.username,
            verficiationDetails.accessToken,
            verficiationDetails.authKey,
            false,
            verficiationDetails.isEmailTokenRequired,
            verficiationDetails.isQrCodeRequired,
          ),
        );
      });
  };
}

// Function to allow the user to have the QR code resent to their email.
export function requestQRCodeEmail(verficiationDetails: VerficationDetails) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // Set authentication in progress.
    dispatch(
      authenticationWith2FAInProgress(
        verficiationDetails.isEmailTokenRequired,
        verficiationDetails.isQrCodeRequired,
        verficiationDetails.username,
        '',
        '',
        verficiationDetails.accessToken,
        verficiationDetails.authKey,
      ),
    );

    client
      .post(
        '/api/v1/require_qr_code',
        {
          auth_key: verficiationDetails.authKey,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${verficiationDetails.accessToken}`,
          },
        },
      )
      .then((qr_response) => {
        if (qr_response.data.status === 200) {
          dispatch(
            authenticateWith2FA(
              verficiationDetails.username,
              '',
              '',
              verficiationDetails.accessToken,
              verficiationDetails.authKey,
              true, // Indicate that the QR code was sent.
              verficiationDetails.isEmailTokenRequired,
              verficiationDetails.isQrCodeRequired,
            ),
          );
        }
      })
      .catch((err) => {
        dispatch(
          twoFactorAuthenticationFailed(
            {
              detail: 'An error occured while attempting to send QR code.',
            },
            verficiationDetails.username,
            verficiationDetails.accessToken,
            verficiationDetails.authKey,
            false,
            verficiationDetails.isEmailTokenRequired,
            verficiationDetails.isQrCodeRequired,
          ),
        );
      });
  };
}

export function logInWith2FA(userDetails: UserDetails2FAWithTokens) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // dispatch(authenticationInProgress());
    dispatch(
      authenticationWith2FAInProgress(
        userDetails.emailCode !== '',
        userDetails.qrCode !== '',
        userDetails.username,
        '',
        '',
        userDetails.accessToken || '',
        userDetails.authKey || '',
      ),
    );

    const formData = new FormData();
    formData.append('auth_key', userDetails.authKey || '');
    if (userDetails.emailCode && userDetails.emailCode !== '') {
      formData.append('email_verification_code', userDetails.emailCode);
    }
    if (userDetails.qrCode && userDetails.qrCode !== '') {
      formData.append('qr_code_verification_code', userDetails.qrCode);
    }

    client
      .post('/api_verification_code', formData, {
        headers: {
          Accept: 'application/json',
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${userDetails.accessToken}`,
        },
      })
      .then((loginResponse) => {
        if (loginResponse.data.login_return_code === 0) {

          FastAPIAuthService.FastAPIlogin2fa(userDetails.accessToken, userDetails.authKey, userDetails.emailCode, userDetails.qrCode)
            .then((response: any) => {
              handleLoginSuccess(dispatch, response, loginResponse);
            })
            .catch((err) => {
              handleLoginError(dispatch, err, 'Error retrieving user details');
            });

        } else {
          dispatch(unauthenticate());
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(
            twoFactorAuthenticationFailed(
              err.response.data,
              userDetails.username,
              userDetails.accessToken || '',
              userDetails.authKey || '',
              false,
              userDetails.isEmailTokenRequired,
              userDetails.isQrCodeRequired,
            ),
          );
        } else if (err.response && err.response.status === 403) {
          dispatch(
            twoFactorAuthenticationFailed(
              err.response.data,
              userDetails.username,
              userDetails.accessToken || '',
              userDetails.authKey || '',
              false,
              userDetails.isEmailTokenRequired,
              userDetails.isQrCodeRequired,
            ),
          );
        } else {
          dispatch(
            twoFactorAuthenticationFailed(
              { detail: 'There was an error logging in.' },
              userDetails.username,
              userDetails.accessToken || '',
              userDetails.authKey || '',
              false,
              userDetails.isEmailTokenRequired,
              userDetails.isQrCodeRequired,
            ),
          );
        }
      });
  };
}
