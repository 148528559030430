import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';
import { usePrompt } from '../../hooks/useBlocker';

type NavigationGuardProps = {
  message?: string;
  when: boolean;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NavigationGuard = ({
  when,
  message = 'Are you sure to leave this page?',
}: NavigationGuardProps) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = usePrompt(when);

  const handleClickNo = () => {
    cancelNavigation();
  };

  const handleClickYes = () => {
    confirmNavigation();
  };

  return (
    <Modal
      visible={showPrompt}
      onCancel={handleClickNo}
      onConfirm={handleClickYes}
      message={message}
    />
  );
};

export default NavigationGuard;

type ModalProps = {
  message?: string;
  visible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const Modal = ({ visible, onCancel, onConfirm, message }: ModalProps) => {
  const confirm = () => {
    onCancel();
    onConfirm();
  };

  return (
    <Dialog
      open={visible}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => onCancel()}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Warning! You are about to leave the page!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {message ||
            'Are you sure you want to leave this page? Unsaved changes will be lost?'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={confirm}>
          Confirm
        </Button>
        <Button onClick={() => onCancel()}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
