import React, { Dispatch, FC, useEffect, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { Status } from '../../../../types/redux/data/dataTypes';
import { mainColors } from '../../../../styling/theme';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch } from 'react-redux';
import { toggleControlBarShowing } from '../../../../redux/ui/controlbar/actions';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import CachedIcon from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';
import useSnackbar from '../../../../hooks/useSnackbar';
import axios from 'axios';
import { BASE_URL } from '../../../../utilities/requestClient';
import { Tooltip, Typography } from '@mui/material';

interface KidsFiguresData {
  fundId: string;
  fundName: string;
  shareClass: string;
  sriCurrent: number;
  sriPublished: number;
  sriStatus: Status;
  averageModerateReturnsCurrent: number;
  averageModerateReturnsPublished: number;
  averageModerateReturnsStatus: Status;
  publishedDate: string;
  calculationDate: string;
  dateStatus: Status;
  overallStatus: Status;
  rowColor: string;
}

export const OverallStatusBox = ({ status }: { status: Status }) => (
  <div
    style={{
      padding: '.8rem',
      borderRadius: 8,
      border: `1px solid ${mapStatusToColor(status)}`,
      backgroundColor: 'white',
    }}
  >
    <Typography
      variant="h3"
      style={{
        transform: 'translateY(1px)',
        color: mapStatusToColor(status),
        fontSize: '1.8rem',
      }}
    >
      Status: {status}
    </Typography>
  </div>
);

function buildKidsFiguresData(
  data: any,
  fundIdFilter: string,
): KidsFiguresData[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  const figuresData = data.data[0];
  const tableData: KidsFiguresData[] = [];
  // Keep track of the fundId (to be used for applying zebra colour to table)
  let currentFundId = figuresData[0].fund_id;
  let currentColorKey = true;
  // Store both of the colour options
  type ColourOptionsType = {
    [key: string]: string;
  };
  const colourOptions: ColourOptionsType = {
    // true: greys.grey100,
    true: '#ffffff',
    // false: greys.grey200
    false: mainColors.controlButtonBlue,
  };
  // Loop through each share class and append to table data.
  figuresData
    .filter((element: any) => {
      if (fundIdFilter === '') return true;
      return element.fund_id === fundIdFilter;
    })
    .forEach((element: any) => {
      // Check if the colour should be changed.
      if (currentFundId !== element.fund_id) {
        currentFundId = element.fund_id;
        currentColorKey = !currentColorKey;
      }
      tableData.push({
        fundId: element.fund_id,
        fundName: element.fund_name,
        shareClass: element.share_class,
        sriCurrent: element.SRI.current_value,
        sriPublished: element.SRI.published_value,
        sriStatus: element.SRI.status as Status,
        averageModerateReturnsCurrent:
          'Average Moderate Return' in element
            ? element['Average Moderate Return'].current_value
            : 'N/A',
        averageModerateReturnsPublished:
          'Average Moderate Return' in element
            ? element['Average Moderate Return'].published_value
            : 'N/A',
        averageModerateReturnsStatus:
          'Average Moderate Return' in element
            ? (element['Average Moderate Return'].status as Status)
            : Status.NA,
        publishedDate: element['Publication Date'].published_value,
        calculationDate: element['Publication Date'].current_value,
        dateStatus: element['Publication Date'].status as Status,
        overallStatus: element.overall_status as Status,
        rowColor: colourOptions[currentColorKey.toString()],
      });
    });

  return tableData;
}

function buildColumns(
  figuresUpdated: boolean,
): CustomColumn<KidsFiguresData>[] {
  const columns: CustomColumn<KidsFiguresData>[] = [
    {
      title: 'Fund ID',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Share Class',
      field: 'shareClass',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'SRI',
      field: 'sriPublished',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      width: '15%',
      render: (rowData: KidsFiguresData) => (
        <CompareFiguresStatusBox
          currentValue={rowData.sriCurrent}
          publishedValue={
            figuresUpdated ? rowData.sriCurrent : rowData.sriPublished
          }
          status={figuresUpdated ? Status.Pass : rowData.sriStatus}
        />
      ),
    },
    {
      // title: 'Average Moderate Returns',
      title: (
        <div style={{ display: 'flex' }}>
          <div>Average Moderate Returns</div>
          <Tooltip
            title="An Alert will be triggered here if the current value moves by more than 0.05 from the published value."
            placement="top"
          >
            <InfoIcon></InfoIcon>
          </Tooltip>
        </div>
      ),
      field: 'averageModerateReturnsPublished',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      // width: '15%',
      render: (rowData: KidsFiguresData) => (
        <CompareFiguresStatusBox
          currentValue={rowData.averageModerateReturnsCurrent}
          publishedValue={
            figuresUpdated
              ? rowData.averageModerateReturnsCurrent
              : rowData.averageModerateReturnsPublished
          }
          status={
            figuresUpdated ? Status.Pass : rowData.averageModerateReturnsStatus
          }
        />
      ),
    },
    {
      title: 'Published Date',
      field: 'publishedDate',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KidsFiguresData) => (
        <CompareFiguresStatusBox
          currentValue={rowData.calculationDate}
          publishedValue={
            figuresUpdated ? rowData.calculationDate : rowData.publishedDate
          }
          status={figuresUpdated ? Status.Pass : rowData.dateStatus}
        />
      ),
    },
    {
      title: 'Overall Status',
      field: 'overallStatus',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KidsFiguresData) => (
        <OverallStatusBox
          status={figuresUpdated ? Status.Pass : rowData.overallStatus}
        />
      ),
    },
  ];
  return columns;
}

interface CompareFiguresStatusBoxProps {
  currentValue: number | string;
  publishedValue: number | string;
  status: Status;
}

function CompareFiguresStatusBox({
  currentValue,
  publishedValue,
  status,
}: CompareFiguresStatusBoxProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid',
        borderRadius: 10,
        minHeight: '6.5rem',
        padding: 0,
        backgroundColor: 'white',
        minWidth: '30rem',
      }}
    >
      <div
        style={{
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          width: '50%',
        }}
      >
        <Typography variant="h3">Published Value</Typography>
        <div>
          <Typography
            variant="h4"
            style={{ color: mainColors.controlButtonBlue }}
          >
            {publishedValue}
          </Typography>
        </div>
      </div>
      <div
        style={{
          backgroundColor: mapStatusToColor(status),
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          borderLeft: '1px solid',
          width: '50%',
        }}
      >
        <Typography variant="h3" style={{ color: 'white' }}>
          Current Calculated
        </Typography>
        <div>
          <Typography variant="h4" style={{ color: 'white' }}>
            {currentValue}
          </Typography>
        </div>
      </div>
    </div>
  );
}

interface FundIdentifiers {
  fundId: string;
  fundName: string;
  fundUUID: string;
}

function getUniquerFundIds(data: any): FundIdentifiers[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  const figuresData = data.data[0];
  const fundIdentifiers: FundIdentifiers[] = [];
  const fundIds: any[] = [];
  figuresData.forEach((element: any) => {
    if (!fundIds.includes(element.fund_id)) {
      fundIds.push(element.fund_id);
      fundIdentifiers.push({
        fundId: element.fund_id,
        fundName: element.fund_name,
        fundUUID: element.fund_uuid,
      });
    }
  });
  return fundIdentifiers;
}

interface KidFiguresTableProps {
  tableData: KidsFiguresData[];
  fundIdentifiers: FundIdentifiers;
}

const KidFiguresTable = ({
  tableData,
  // figuresUpdated,
  fundIdentifiers,
}: KidFiguresTableProps) => {
  const [figuresUpdated, setFiguresUpdated] = useState(false);
  const columns = buildColumns(figuresUpdated);
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h2"
          style={{
            marginBottom: '1rem',
            marginLeft: '1rem',
            marginTop: '1rem',
          }}
        >{`KIDS Figures - ${fundIdentifiers.fundName}`}</Typography>
        <div
          style={{
            marginRight: '1rem',
            marginTop: '1rem',
          }}
        >
          <RefreshFiguresButton
            fundId={fundIdentifiers.fundId}
            fundUUID={fundIdentifiers.fundUUID}
            fundName={fundIdentifiers.fundName}
            setFiguresUpdated={setFiguresUpdated}
          />
        </div>
      </div>
      <CustomTable<KidsFiguresData>
        title={'KIDS Figures'}
        showToolbar={false}
        id={`kiids_editor_overview_table`}
        // loading={kidsFiguresData && !kidsFiguresData.isFetching ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: false,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
          draggable: false,
        }}
        columns={columns}
      />
    </>
  );
};

interface RefreshFiguresButtonProps {
  fundId: string;
  fundUUID: string;
  fundName: string;
  setFiguresUpdated: Dispatch<any>;
}

export function RefreshFiguresButton({
  fundId,
  fundUUID,
  fundName,
  setFiguresUpdated,
}: RefreshFiguresButtonProps): React.ReactElement {
  // kiid_generator_refresh_figures_data/<fund_id>/<fund_uuid></fund_uuid>
  const requestClient = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
  });
  const { showAsyncSnackbar } = useSnackbar();

  async function handleFiguresUpdate() {
    // Have the user confirm if they want to update all share classes
    const confirm = window.confirm(
      `Updating will update figures fo all share classes of ${fundName}. Do you wish to continue?`,
    );
    if (!confirm) return;
    // Save the data to the DB
    await showAsyncSnackbar({
      startMessage: 'Updating Figures.',
      successMessage: 'Changes Made.',
      failureMessage: 'An Error occured while making changes.',
      promiseToResolve: requestClient.get(
        `kiid_generator_refresh_figures_data/${fundId}/${fundUUID}`,
      ),
    });
    setFiguresUpdated(true);
  }

  return (
    <Button
      variant="contained"
      startIcon={<CachedIcon />}
      onClick={handleFiguresUpdate}
    >
      {`Refresh Figures Data`}
    </Button>
  );
}

const KidsFigures: FC = () => {
  const kidsFiguresData = useFetchData({
    url: 'get_kid_figures_data',
    keyName: 'kids_figures_data',
    makeFetch: true,
  });

  const dispatch = useDispatch();
  // Ensure that the control bar does not show up.
  useEffect(() => {
    dispatch(toggleControlBarShowing(false));
  }, []);
  const tableData = buildKidsFiguresData(kidsFiguresData, '');
  const fundIdentifiers = getUniquerFundIds(kidsFiguresData);

  return (
    // <GridItem xs={12} card>
    <GeneralComponentErrorShield
      dataObjects={[kidsFiguresData]}
      customLoadingMessages={[
        'Loading Latest Kid Figures Data...',
        'This may take a few seconds...',
        '...',
      ]}
    >
      {fundIdentifiers.map((item) => (
        <GridItem xs={12} card>
          <KidFiguresTable
            tableData={buildKidsFiguresData(kidsFiguresData, item.fundId)}
            fundIdentifiers={item}
            // figuresUpdated={figuresUpdated}
          />
        </GridItem>
      ))}
    </GeneralComponentErrorShield>
  );
};

export default KidsFigures;
