import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormEvent, useEffect, useState } from 'react';
import { greys, mainColors } from '../../../styling/theme';
import {
  accessTokenSelector,
  authErrorSelector,
  authIsEmailTokenRequiredSelector,
  authIsLoadingSelector,
  authKeySelector,
  isLoggedInSelector,
  userIdSelector,
} from '../../../redux/auth/selectors';
import {
  cancel2FALogin,
  logIn,
  logInWith2FA,
} from '../../../redux/auth/actions';
import RaptorWithRs from '../../../images/RaptorWithRs';
import { zIndexes } from '../../../styling/zIndexes';
import clsx from 'clsx';
import { ClassNameMap, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Help, Visibility, VisibilityOff } from '@mui/icons-material';
import { AccountDeactivationDates } from '../../settings/pages/manageAccounts/subComponents/UserList.component';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Extend Day.js with the advancedFormat plugin
dayjs.extend(advancedFormat);

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12vh 8vw 0 8vw',
    minWidth: 920,
    position: 'relative',
    height: '100%',
    zIndex: zIndexes.content,
  },
  raptorWithRs: {
    width: 200,
    marginBottom: '2rem',
  },
  pageTitle: {
    fontSize: '2.4rem',
    fontWeight: 500,
    marginBottom: '2rem',
    color: mainColors.mainBlue,
  },
  errorTitle: {
    fontSize: '1.6rem',
    fontWeight: 500,
    marginBottom: '1rem',
    color: mainColors.Fail,
    width: '32rem',
  },
  description: {
    fontSize: '1.4rem',
    fontWeight: 400,
    marginBottom: '1rem',
    color: greys.grey600,
    width: '32rem',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    marginTop: '1rem',
  },
  forgotPassword: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    padding: '0 0 0.5rem 0',
    color: greys.grey600,
    fontWeight: 300,
    '& a': {
      color: mainColors.mainBlue,
      textDecoration: 'none',
    },
  },
  input: {
    padding: '1rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${greys.grey100}`,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: greys.grey800,
    '&::placeholder': {
      color: greys.grey400,
    },
    width: '32rem',
  },
  input_password: {
    padding: '1rem 4rem 1rem 0.5rem',
    borderRadius: '0.4rem',
    border: `1px solid ${greys.grey100}`,
    fontSize: '1.5rem',
    fontWeight: 400,
    color: greys.grey800,
    '&::placeholder': {
      color: greys.grey400,
    },
    width: '32rem',
  },
  submitButton: {
    all: 'unset',
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    cursor: 'pointer',
    backgroundColor: mainColors.mainBlue,
    textAlign: 'center',
  },
  submitButton_inactive: {
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    backgroundColor: greys.grey300,
    textAlign: 'center',
  },
  cancelButton: {
    all: 'unset',
    marginTop: '1rem',
    width: '32rem',
    padding: '1rem 0',
    borderRadius: '0.4rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    color: 'white',
    cursor: 'pointer',
    backgroundColor: mainColors.mainGold,
    textAlign: 'center',
  },
  passwordInput: {
    width: 'fit-content',
    position: 'relative',
  },
  showPasswordButton: {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translateY(-40%)',
    cursor: 'pointer',
    '& svg': {
      fontSize: '2.5rem',
      color: greys.grey600,
    },
  },
  feedbackContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '1rem',
  },
  authMessage: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: greys.grey600,
    width: '32rem',
  },
  privacyLink: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
    width: '32rem',
  },
  privacyLinkText: {
    color: mainColors.mainBlue,
    fontSize: '1.2rem',
    backgroundColor: 'white',
    width: 'fit-content',
    padding: '0 0.2rem',
    borderRadius: '0.2rem',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  input_valid: {
    border: `1px solid ${mainColors.Pass}`,
    backgroundColor: mainColors.Pass_veryLight,
  },
  input_not_valid: {
    border: `1px solid ${mainColors.Fail}`,
    backgroundColor: mainColors.Fail_veryLight,
  },
  inputValidationMessageContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '0.5rem',
    '& svg': {
      fontSize: '1.6rem',
      color: greys.grey600,
    },
  },
  inputValidationMessage: {
    fontSize: '1.2rem',
    fontWeight: 400,
    color: mainColors.mainBlue,
  },
  inputValidationMessage_valid: {
    color: mainColors.Pass,
  },
  inputValidationMessage_not_valid: {
    color: mainColors.Fail,
  },
  tooltipContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  tooltipTitle: {
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  tooltipItem: {
    fontSize: '1rem',
  },
}));

const getAuthMessageColor = (authStatus: 'loading' | 'success' | 'error') => {
  switch (authStatus) {
    case 'loading':
      return greys.grey600;
    case 'success':
      return mainColors.Pass;
    case 'error':
      return mainColors.Fail;
  }
};

const validateEmailCode = (emailCode: string): boolean => {
  // email code must be 6 integers long
  return /^\d{6}$/.test(emailCode);
};

const emailCodeValidationStyles = (
  emailCode: string,
  classes: ClassNameMap,
) => {
  if (emailCode.length === 0) {
    return classes.input_password;
  } else if (emailCode.length > 0 && validateEmailCode(emailCode)) {
    return clsx(classes.input_password, classes.input_valid);
  } else {
    return clsx(classes.input_password, classes.input_not_valid);
  }
};

const LogIn = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [emailCode, setEmailCode] = useState('');
  const [showEmailCode, setShowEmailCode] = useState(false);

  const authIsLoading = useSelector(authIsLoadingSelector);
  const authErrorObject = useSelector(authErrorSelector);
  const isAuthenticated = useSelector(isLoggedInSelector);

  // Check if 2FA is required
  const authEmailTokenRequired = useSelector(authIsEmailTokenRequiredSelector);

  const userId = useSelector(userIdSelector);

  // this function takes the user input for username and auto formats it with our desired format
  // currently this just means making all characters lowercase
  const handleUsernameInputEnforcement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUsername(event.target.value.toLowerCase());
  };

  const handleEmailCodeInputEnforcement = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // only allow numbers, and a max length of 6
    setEmailCode(event.target.value.replace(/\D/g, '').substring(0, 6));
  };

  // state for auth status and message
  const [authStatus, setAuthStatus] = useState<'loading' | 'success' | 'error'>(
    'loading',
  );
  const [authMessage, setAuthMessage] = useState<string>('');
  const [supplementalAuthMessage, setSupplementalAuthMessage] =
    useState<string>('');

  // state for countdown timer
  const [countDownActive, setCountDownActive] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);

  // get the authentication key
  const authKey = useSelector(authKeySelector);
  const accessToken = useSelector(accessTokenSelector);

  const handle2FASubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setAuthMessage('Signing you in...');
    setAuthStatus('loading');
    dispatch(
      logInWith2FA({
        username,
        emailCode,
        qrCode: null,
        authKey,
        accessToken,
        isQrCodeRequired: false,
        isEmailTokenRequired: authEmailTokenRequired || false,
      }),
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    dispatch(logIn({ username, password }));
    setShowPassword(false);
  };

  useEffect(() => {
    let interval: any = undefined;

    if (countDownActive && timeRemaining > 0) {
      interval = setInterval(() => {
        setTimeRemaining((timeRemaining) => timeRemaining - 1);
        const formattedTime = `${Math.floor(timeRemaining / 60)}:${
          timeRemaining % 60 < 10
            ? `0${timeRemaining % 60}`
            : timeRemaining % 60
        }`;
        setSupplementalAuthMessage(`Account frozen for ${formattedTime}`);
      }, 1000);
    } else if (countDownActive && timeRemaining <= 0) {
      clearInterval(interval);
      setCountDownActive(false);
      setAuthStatus('success');
      setAuthMessage('Account unfrozen.');
      setSupplementalAuthMessage('');
    }
    return () => clearInterval(interval);
  }, [countDownActive, timeRemaining]);

  const handleStartCountdown = (targetTime: Date) => {
    setTimeRemaining(
      Math.floor((targetTime.getTime() - new Date().getTime()) / 1000),
    );
    setCountDownActive(true);
  };

  const handleResetCountdown = () => {
    setTimeRemaining(0);
    setCountDownActive(false);
  };

  useEffect(() => {
    if (authIsLoading) {
      setAuthStatus('loading');
      setAuthMessage('Signing you in...');
      setSupplementalAuthMessage('');
    } else if (authErrorObject) {
      setAuthStatus('error');
      setAuthMessage(authErrorObject.detail);
      if (authErrorObject.account_is_blocked === false) {
        // this means the user is not blocked, but the login failed
        // this could be because of an incorrect password, incorrect username, or some other reason
        if (authErrorObject.detail === 'Incorrect credentials') {
          setAuthMessage('Incorrect credentials.');

          // TODO: Darrach Cleanup needed in future
          if (!authErrorObject.maximum_failed_login_attempts) {
            setAuthMessage('Incorrect credentials.');
            return;
          }
          if (authErrorObject.maximum_failed_login_attempts !== 0) {
            setSupplementalAuthMessage(
              `${
                (authErrorObject.maximum_failed_login_attempts ?? 0) -
                (authErrorObject.failed_attempts ?? 0)
              } attempts remaining before login is disabled for ${
                authErrorObject.account_frozen_minutes ?? 0
              } minute${
                authErrorObject.account_frozen_minutes === 1 ? '' : 's'
              }.`,
            );
          }
        } else if (authErrorObject.detail === 'Incorrect code provided') {
          setAuthMessage('Incorrect code provided.');

          // TODO: Darrach Cleanup needed in future
          if (!authErrorObject.maximum_failed_login_attempts) {
            setAuthMessage('Incorrect credentials.');
            return;
          }

          if (authErrorObject.maximum_failed_login_attempts !== 0) {
            setSupplementalAuthMessage(
              `${
                (authErrorObject.maximum_failed_login_attempts ?? 0) -
                (authErrorObject.failed_attempts ?? 0)
              } attempts remaining before login is disabled for ${
                authErrorObject.account_frozen_minutes ?? 0
              } minute${
                authErrorObject.account_frozen_minutes === 1 ? '' : 's'
              }.`,
            );
          }
        } else {
          setAuthMessage('An unknown error occurred.');
          setSupplementalAuthMessage(
            'If this issue persists, please contact RiskSystem support.',
          );
        }
      } else if (authErrorObject.account_is_blocked === true) {
        // this means the user has exceeded the maximum number of login attempts, and the account is now temporarily blocked
        setAuthMessage('Maximum login attempts exceeded.');
        if (authErrorObject.account_is_blocked_from) {
          // Append 'Z' to the string to indicate it's UTC if it doesn't have one
          const utcDateTimeString =
            authErrorObject.account_is_blocked_from.endsWith('Z')
              ? authErrorObject.account_is_blocked_from
              : `${authErrorObject.account_is_blocked_from}Z`;
          //target time is the time the account is blocked from, pluse the account_frozen_minutes
          const targetTime = new Date(utcDateTimeString);
          targetTime.setMinutes(
            targetTime.getMinutes() +
              (authErrorObject.account_frozen_minutes ?? 0),
          );
          setSupplementalAuthMessage(
            `Account frozen for ${authErrorObject.account_frozen_minutes}:00`,
          );
          handleStartCountdown(targetTime);
        }
      }
    } else if (isAuthenticated && userId) {
      handleResetCountdown();
      setAuthStatus('success');
      setAuthMessage('Success! You are logged in.');
    }
  }, [authIsLoading, authErrorObject, isAuthenticated, userId]);

  useEffect(() => {
    if (authEmailTokenRequired) {
      setAuthMessage(''), setAuthStatus('loading');
    }
  }, [authEmailTokenRequired]);

  const handlCancel2FALogin = () => {
    setEmailCode('');
    setAuthMessage('');
    setSupplementalAuthMessage('');
    setShowEmailCode(false);
    dispatch(cancel2FALogin());
  };

  return (
    <div className={classes.mainContainer}>
      <RaptorWithRs className={classes.raptorWithRs} />

      {!authEmailTokenRequired && (
        <div>
          <div className={classes.pageTitle}>Log In</div>
          <div className={classes.description}>
            Please enter your username and password.
          </div>
          <div className={classes.forgotPassword}>
            <div>Forgot your password?</div>
            <a href="reset-password">Reset</a>
          </div>
          <form onSubmit={handleSubmit} className={classes.inputsContainer}>
            <input
              id="username-input"
              type="text"
              placeholder="Username / Email"
              autoComplete="username"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              value={username}
              onChange={(e) => handleUsernameInputEnforcement(e)}
              className={classes.input}
            />
            <div className={classes.passwordInput}>
              <input
                id="password-input"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                autoComplete="password"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={classes.input_password}
              />
              <div
                className={classes.showPasswordButton}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>
            {
              // [2024-08-27, Tom Walsh] - Temporary code to remove '_funds' accounts from the list after corresponding deactivation date
              // Show error message if the account is going to be deactivated
              Object.keys(AccountDeactivationDates).includes(username) ? (
                <div
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: 400,
                    color: 'white',
                    marginTop: '1rem',
                    backgroundColor: mainColors.Fail,
                    padding: '0.8rem',
                    borderRadius: '0.4rem',
                    width: '32rem',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  {/* If current date before AccountDeactivationDates[username], show message that will be deactivated */}
                  {/* Else, show message that is now deaectivated */}
                  {dayjs(AccountDeactivationDates[username]) > dayjs() ? (
                    <>
                      <div>
                        The account <b>'{username}'</b> will be deactivated on{' '}
                        {dayjs(AccountDeactivationDates[username]).format(
                          'Do MMMM, YYYY',
                        )}
                        .
                      </div>
                      <div>
                        If you are still using this account, please contact
                        RiskSystem support.
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        The account <b>'{username}'</b> was deactivated on{' '}
                        {dayjs(AccountDeactivationDates[username]).format(
                          'Do MMMM, YYYY',
                        )}
                        .
                      </div>
                      <div>
                        You can no longer use this account to log in. If this is
                        an error, please contact RiskSystem support.
                      </div>
                    </>
                  )}
                </div>
              ) : null
              // [2024-08-27, Tom Walsh] - End of temporary code
            }
            <button type="submit" className={classes.submitButton}>
              Login
            </button>
            <div className={classes.feedbackContainer}>
              {authMessage && (
                <div
                  className={classes.authMessage}
                  style={{
                    color: getAuthMessageColor(authStatus),
                  }}
                >
                  {authMessage}
                </div>
              )}
              {supplementalAuthMessage && (
                <div
                  className={classes.authMessage}
                  style={{
                    color: getAuthMessageColor(authStatus),
                  }}
                >
                  {supplementalAuthMessage}
                </div>
              )}
            </div>
          </form>
        </div>
      )}

      {authEmailTokenRequired && (
        <div>
          <div className={classes.pageTitle}>
            Two Factor Authentication Required
          </div>
          <div className={classes.description}>
            An authentication code has been sent to the email address associated
            with your account.
          </div>
          <form onSubmit={handle2FASubmit} className={classes.inputsContainer}>
            <div className={classes.inputGroup}>
              <div className={classes.passwordInput}>
                <input
                  id="password-input"
                  type={showEmailCode ? 'text' : 'password'}
                  placeholder="Authentication Code"
                  autoComplete="new-password"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck="false"
                  value={emailCode}
                  onChange={(e) => {
                    handleEmailCodeInputEnforcement(e);
                  }}
                  className={emailCodeValidationStyles(emailCode, classes)}
                />
                <div
                  className={classes.showPasswordButton}
                  onClick={() => setShowEmailCode(!showEmailCode)}
                >
                  {showEmailCode ? <VisibilityOff /> : <Visibility />}
                </div>
              </div>
              <div className={classes.inputValidationMessageContainer}>
                <Tooltip
                  title={
                    <div className={classes.tooltipContainer}>
                      <div className={classes.tooltipTitle}>
                        The authentication code is a 6-digit number.
                      </div>
                    </div>
                  }
                  placement="right-end"
                >
                  <Help />
                </Tooltip>
                {emailCode.length > 0 ? (
                  validateEmailCode(emailCode) ? (
                    <div
                      className={clsx(
                        classes.inputValidationMessage,
                        classes.inputValidationMessage_valid,
                      )}
                    >
                      Code valid.
                    </div>
                  ) : (
                    <div
                      className={clsx(
                        classes.inputValidationMessage,
                        classes.inputValidationMessage_not_valid,
                      )}
                    >
                      Code not valid.
                    </div>
                  )
                ) : null}
              </div>
            </div>
            {validateEmailCode(emailCode) ? (
              <button type="submit" className={classes.submitButton}>
                Login
              </button>
            ) : (
              <div className={classes.submitButton_inactive}>Login</div>
            )}
            <button
              type="submit"
              className={classes.cancelButton}
              onClick={handlCancel2FALogin}
            >
              Cancel
            </button>
            <div className={classes.feedbackContainer}>
              {authMessage && (
                <div
                  className={classes.authMessage}
                  style={{
                    color: getAuthMessageColor(authStatus),
                  }}
                >
                  {authMessage}
                </div>
              )}
              {supplementalAuthMessage && (
                <div
                  className={classes.authMessage}
                  style={{
                    color: getAuthMessageColor(authStatus),
                  }}
                >
                  {supplementalAuthMessage}
                </div>
              )}
            </div>
          </form>
        </div>
      )}

      <div className={classes.privacyLink}>
        <a href=" https://www.risksystem.com/privacy/">
          <p className={classes.privacyLinkText}>
            <u>Privacy Statement</u>
          </p>
        </a>
      </div>
    </div>
  );
};

export default LogIn;
