import { FC } from 'react';
import LoggedInRoute from '../../../../routes/LoggedInRoute';
import FactSheetsOverview from './overview/FactSheetsOverview.component';
import { Navigate, Route } from 'react-router-dom';

const FactSheets: FC = (props) => {
  return (
    // Here we route to different sections of the delegated acts functionality
    <>
      <Route
        path="/raidr/fact-sheets"
        element={<Navigate to="/raidr/fact-sheets/overview" />}
      />
      <Route
        path="/raidr/fact-sheets/overview"
        element={<LoggedInRoute Component={FactSheetsOverview} />}
      />
    </>
  );
};

export default FactSheets;
