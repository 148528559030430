import { ComponentType } from 'react';
import { Forbidden } from '../components/settings/pages/Forbidden';
import PageNotFound from '../components/settings/pages/PageNotFound.component';
import { Section } from '../types/server-data/ClientConfig';

type WithAuthorizationProps = {
  Component: ComponentType<any>;
  section: string;
  pageId: string;
  clientSections: Section[] | null;
};

/**
 * The `withAuthorization` HOC applies authorization logic based on the provided `section` and `pageId`.
 * If the user has access, it renders the `Component` with the additional `props` passed to it.
 *
 * @param {React.ComponentType<any>} Component - The rendered component on pass.
 * @param {string} section - The section
 * @param {string} pageId - The page
 * @param {Section[] | null} clientSections - The client sections
 *
 * @returns {React.ReactElement} - Returns the authorized component with `props` bound, or redirects if unauthorized.
 */

const withAuthorization = (
  Component: React.ComponentType,
  section: string,
  pageId: string,
  clientSections: Section[] | null,
) => {
  const clientAllowedSection = clientSections?.find(
    (mappedSection) => mappedSection.sectionId === section,
  );

  const hasAccess = clientAllowedSection?.pages?.includes(pageId);

  if (!ALL_RAPTOR_PAGES.includes(pageId)) {
    return <PageNotFound />;
  }

  return hasAccess ? <Component /> : <Forbidden />;
};

export default withAuthorization;

const ALL_RAPTOR_PAGES = [
  'exposure',
  'value-at-risk',
  'ucits-law',
  'nurs-law',
  'riaif-law',
  'qiaif-law',
  'act40-law',
  'fivg-law',
  'partii-law',
  'notes',
  'prospectus-restrictions',
  'cssf-risk-spreading',
  'counterparty',
  'stress-scenario-tests',
  'pe-stress-scenario-tests',
  'pe-value-at-risk',
  'historical-data',
  'risk-comparison',
  'var-backtest',
  'sub-fund-setup',
  'fails',
  'historical-fails',
  'aifmd-reports',
  'equity',
  'srri',
  'srri-share-class',
  'share-class-hedge',
  'currency',
  'fixed-income',
  'cis',
  'fund-asset-liquidation-time',
  'lasdab',
  'moab',
  'rst-fixed',
  'rst-variable',
  'liquidation-risk-overview',
  'counterparty-market-liquidity-risk',
  'liquidity-risk-evolution',
  'reverse-stress-test-evolution',
  'esg',
  'subscriptions-redemptions',
  'discount-cashflow',
  'pe-heat-map',
  'pe-exposure',
  'market-liquidity-risk',
  'variable-market-volume-liquidation-costs',
  'liquidity-pe-value-at-risk',
  'pe-market-risk-overview',
  'pe-monte-carlo-var',
  'pe-liquidity-discount-analysis',
  'discount-cashflow-configuration',
  'discount-cashflow-overview',
  'performance-report',
  'collateral-monitor',
  'performance-diagnostics',
  'sub-fund-performance',
  'asset-allocation-performance',
  'asset-and-currency',
  'sub-fund-cumulative-breakdown',
  'initial-exposure-vs-cumulative-returns',
  'sub-fund-historical-performance',
  'sub-fund-vs-benchmark',
  'stock-selection-performance',
  'portfolio-esg',
  'article-scanner',
  'principle-adverse-impact',
  'carbon-emissions',
  'environmental-esg',
  'social-esg',
  'corporate-governance-esg',
  'esg-exposure',
  'esg-stress-tests',
  'esg-var',
  'indicator-compliance',
  'indicator-coverage-ratio-monitor',
  'es-alignment-monitor',
  'historical-data-export',
  'counterparty-stress-tests',
  'alternative-non-cis',
  'pe-proxy-model',
  'relative-risk',
  'cashflow-stress-tests',
  'fx-rates',
  'position-update-portal',
  'overview',
  'pa-overview',
  'aifmd-overview',
  'aifmd-overview-legacy',
  'liquidity-overview',
  'pa-overview-alternative',
  'manco-dashboard',
  'coverage-ratio',
  'library',
  'deceased',
  'nurs-deceased',
  'sif-raif-deceased',
  'global-exposure',
  'status-change',
  'aggregate-liquidation-time',
  'functionality',
  'srri-share-class-overview',
  'esg-overview',
  'restricted-companies',
  'emir',
  'adepa-emir',
  'pe-overview',
  'raptor-export',
  'raptor-export-today',
  'kpi-monitoring',
  'manco-exposure',
  'share-class-hedge-summary',
  'kiids-editor',
  'priips-kid',
  'kiids-editor-viewer',
  'report-delivery-oversight',
  'hedge-derivatives-selector',
  'hybrid-bonds-selector',
  'manco-counterparty-exposure',
  'manco-position-oversight',
  'manco-liquidation-time',
  'manco-equity',
  'manco-fixed-income',
  'manco-cis',
  'delegated-acts',
  'fact-sheets',
  'kids-figures',
  'markets',
  'yield-curve',
  'quayside-share-class-hedge',
  'market-overview',
  'pa-report-generator',
  'fixed-income-report-generator',
  'sanctions-scanner',
  'priips-kids-overview',
  'ucits-kiids-overview-edit',
  'ucits-kiids-overview-view',
  'delegated-acts-overview',
  'fact-sheets-overview',
  'delegated-acts-editor',
  'fact-sheets-editor',
  'priips-kids-editor',
  'ucits-kiids-editor',
  'priips-kids-review',
  'ucits-kiids-review',
  'priips-kids-compare-versions',
  'ucits-kiids-compare-versions',
  'priips-kids-view',
  'ucits-kiids-view',
];
