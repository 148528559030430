import { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector } from '../redux/auth/selectors';
import { updateSection } from '../redux/pages/actions';
import withAuthorization from '../hoc/withAuthorisation';
import { clientSectionsSelector } from '../redux/pages/selectors';

const sectionExpections = ['dashboard', 'settings', 'documentation'];

interface AuthenticatedPageProps {
  Component: React.ComponentType<any>; // The component to render
}

const AuthenticatedPage = ({
  Component,
}: AuthenticatedPageProps): JSX.Element => {
  const location = useLocation();
  const { section, pageId } = useParams<{ section: string; pageId: string }>();
  const clientSections = useSelector(clientSectionsSelector);
  const dispatch = useDispatch();

  // Update section whenever the section param changes
  useEffect(() => {
    if (section) {
      dispatch(updateSection(section));
    }
  }, [section, dispatch]);

  // Handle the exception routes
  const nonSectionParams = location.pathname.split('/')[1];

  if (sectionExpections.includes(nonSectionParams)) {
    if (
      nonSectionParams === 'documentation' ||
      nonSectionParams === 'dashboard' ||
      nonSectionParams === 'settings'
    ) {
      return <Component />;
    }
  }

  // Wrap the component with authorization logic
  return withAuthorization(Component, section!, pageId!, clientSections);
};

interface LoggedInRouteProps {
  Component: React.ComponentType<any>;
}

const LoggedInRoute = ({ Component }: LoggedInRouteProps): JSX.Element => {
  const isAuthenticated = useSelector(isLoggedInSelector);
  const location = useLocation(); // Ensure location is available here

  // Redirect to login page if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If authenticated, render AuthenticatedPage
  return <AuthenticatedPage Component={Component} />;
};

export default LoggedInRoute;
