import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from '../redux/auth/selectors';
import { openingRouteSelector } from '../redux/pages/selectors';

interface LoggedInRouteProps {
  Component: React.ComponentType<any>;
}
const LoggedOutRoute = ({ Component }: LoggedInRouteProps) => {
  const navigate = useNavigate();
  const openingRoute = useSelector(openingRouteSelector)!;
  const isAuthenticated = useSelector(isLoggedInSelector);
  const location = useLocation();

  useEffect(() => {
    const { from } = (location.state as { from: any }) || {
      from: { pathname: '/' },
    };

    if (isAuthenticated) {
      // If the user was brought to the login page from another page, redirect them back to that page.
      // else, redirect them to the opening route. (e.g. /dashboard)
      // Maybe this should be changed, should it be the first page of the first section and not hardcoded?
      if (from.pathname !== '/' && from.pathname !== null) {
        navigate(from, { replace: true });
      } else if (openingRoute) {
        navigate(openingRoute);
      } else {
        navigate('/');
      }
    }
  }, [history, isAuthenticated, openingRoute]);

  return <Component />;
};

export default LoggedOutRoute;
