import React, { FC, useEffect } from 'react';
import { FundInfoComponentProps } from '../../../general/GeneralFundInfoPage';
import useFetchData from '../../../../hooks/useFetchData';
import LawFirstLevelTable from './lawComponents/LawFirstLevelTable';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
  updateTitleBarSelectValue,
} from '../../../../redux/pages/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import { activePageSelector } from '../../../../redux/pages/selectors';

const QiaifLaw: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  const dispatch = useDispatch();
  const activePage = useSelector(activePageSelector);

  // [2024-11-22] - The selector for choosing historical data is temporarily removed for now until Lavy has time to implement it on backend.
  // // Add the select components to the title bar
  // useEffect(() => {
  //   const selectsToAdd: Select[] = [
  //     {
  //       titleBarTitle: 'Data Display: ',
  //       titleBarKey: 'dataDisplay',
  //       values: [
  //         {
  //           label: 'Current',
  //           value: 'current',
  //         },
  //         {
  //           label: 'Historical',
  //           value: 'historical',
  //         },
  //       ],
  //       displayOnOverviewPage: false,
  //       aValueIsAlwaysSelected: true,
  //       currentValue: 'current',
  //     },
  //   ];

  //   dispatch(
  //     addAdditionalTitleBarComponents(
  //       selectsToAdd,
  //       APPEND_NEW_SELECT_TO_TITLEBAR,
  //     ),
  //   );

  //   return () => {
  //     dispatch(
  //       removeTitleBarComponent('dataDisplay', REMOVE_SELECT_FROM_TITLEBAR),
  //     );
  //   };
  // }, [activePage]);

  const qiaifComplianceChecklist = useFetchData({
    url: 'compliance_checklist/QIAIF',
    keyName: 'qiaif_compliance_checklist',
    makeFetch: true,
  });

  const basicDataAgg = useFetchData({
    url: formatFundUrl('basic_data_agg', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'basicDataAgg', positionDate), // this is the same as var
    makeFetch: true,
  });

  const qiaifFundData = useFetchData({
    url: formatFundUrl('fund_QIAIF_data', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'qiaifFundData', positionDate),
    makeFetch: true,
  });

  return qiaifComplianceChecklist && basicDataAgg && qiaifFundData ? (
    <LawFirstLevelTable
      dataSource={{
        complianceChecklist: qiaifComplianceChecklist,
        basicDataAgg,
        qiaifFundData,
      }}
      activeFundName={fundName}
      activeFund={fundId}
    />
  ) : null;
};

export default QiaifLaw;
