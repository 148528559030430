import React, { FC, useEffect } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import { mainColors } from '../../../../../styling/theme';
import RaptorSelect from '../../../../selects/RaptorSelect.component';
import DownloadFileButton from '../buttons/DownloadFilesButton.component';
import { Tooltip } from '@mui/material';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import KidsOverviewAllFunctionalitySecondLevelTable from './KidsOverviewAllFunctionalitySecondLevelTable';
import KiidsOverviewViewOnlySecondLevelTable from './KidsOverviewViewOnlySecondLevelTable';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { useKIDSOverviewData } from '../services/queries';
import { KIDOverviewData } from '../../../../../types/pages/raidr.types';
import TableLoader from '../../../../ui/TableLoader';
import { useKidsOverviewStyles } from '../styles/KidsOverview.styles';
import ErrorBoundaryMessage from '../../../../ui/ErrorBoundaryMessage';
import NoDataMessage from '../../../../feedback/NoDataMessage.component';

export interface KidsOverviewTableData {
  fundId: string;
  fundUUID: string;
  fundName: string;
  numShareClasses: number;
  unresolvedComments: number;
  secondLevelData: KidsOverviewSecondLevelTableData[];
}
export interface KidsOverviewSecondLevelTableData {
  fundId: string;
  fundName: string;
  isin: string;
  publicationDate: string;
  lasteEditedDate: string;
  lastEditedBy: string;
  version: string;
  fund_uuid: string;
  kiid_uuid: string;
  unresolvedComments: number;
  versionNumbers: string[];
  index: number;
  documentLanguage: string;
  shareClassName: string;
  versionStatus: string;
}

// Function to display a message based on the version number of the document
function getVersionStatus(version: string): string {
  // Split the version number into major, minor and patch
  const versionNumbers = version.split('.');
  const major = parseInt(versionNumbers[0]);
  const minor = parseInt(versionNumbers[1]);
  const patch = parseInt(versionNumbers[2]);
  // If the patch is greate than 0 then edits have been made.
  if (patch > 0) return 'Edits made to document, review is available.';
  // If the minor is greater than 0 and the path is 0 then a review is available
  if (minor > 0) return 'Review completed, document is availbe for publishing.';
  // Otherwise the document has been published (minor and patch are 0)
  return 'Latest Published version, no changes have been made.';
}

function buildKidsEditorOverviewTableData(
  data: any[],
  selectedLanguage: string | undefined,
): KidsOverviewTableData[] {
  if (!selectedLanguage) return [];
  const kiidsData = data;
  // Create an object for storing the data.
  type DataStore = {
    [key: string]: KidsOverviewTableData;
  };
  const dataStore: DataStore = {};
  // Loop through the data and add to the data store.
  kiidsData
    .filter((value: any) => value.document_language === selectedLanguage)
    .forEach((value: any, index: number) => {
      // Build the second level data first
      const secondLevelData: KidsOverviewSecondLevelTableData = {
        fundId: value.fund_id_string,
        fundName: value.fund_name,
        isin: value.share_class,
        publicationDate: value.publication_timestamp,
        lasteEditedDate: value.last_edit_timestamp,
        lastEditedBy: value.last_edited_by_name,
        version: value.version,
        fund_uuid: value.fund_id,
        kiid_uuid: value.kiid_id,
        unresolvedComments: value.num_unresolved_comments,
        versionNumbers: value.version_numbers,
        index: index,
        documentLanguage: value.document_language,
        shareClassName: value.share_class_name,
        versionStatus: getVersionStatus(value.version),
      };
      // Check if this fund is NOT already in the data store
      if (!(value.fund_id_string in dataStore)) {
        secondLevelData.index = 0;
        // Create a new entry in the datastore
        dataStore[value.fund_id_string] = {
          fundId: value.fund_id_string,
          fundUUID: value.fund_id,
          fundName: value.fund_name,
          numShareClasses: 1,
          unresolvedComments: value.num_unresolved_comments,
          secondLevelData: [secondLevelData],
        };
      } else {
        // Otherwise just increment number of share classes and append to second level data.
        dataStore[value.fund_id_string].numShareClasses += 1;
        dataStore[value.fund_id_string].unresolvedComments +=
          value.num_unresolved_comments;
        secondLevelData.index =
          dataStore[value.fund_id_string].secondLevelData.length;
        dataStore[value.fund_id_string].secondLevelData.push(secondLevelData);
      }
    });
  // Get just the values form the datastore and return this.
  const dataStoreValues = Object.values(dataStore);

  return dataStoreValues;
}

// Function for building the columns for the table based on the download handler provided
function buildTableColumns(
  mancoId: string,
  selectedLanguage: string | undefined,
): CustomColumn<KidsOverviewTableData>[] {
  if (!selectedLanguage) return [];
  const detailColumns: CustomColumn<KidsOverviewTableData>[] = [
    {
      title: 'Fund ID',
      field: 'fundId',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      defaultSort: 'asc',
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: '#Share Classes',
      field: 'numShareClasses',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Download',
      field: '',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: KidsOverviewTableData) => {
        return (
          <DownloadFileButton
            mancoId={mancoId}
            fundId={rowData.fundId}
            fundName={rowData.fundName}
            tooltipMessage="Download documents for all share classes in this fund. This may take a few moments to complete."
            documentLanguage={selectedLanguage}
          />
        );
      },
    },
  ];
  return detailColumns;
}

// Define a function for finding the primary language in the data
function findPrimaryLanguage(data: KIDOverviewData[]): string | undefined {
  const primary = data.find(
    (value) =>
      value.document_language === 'English' || value.is_primary_language,
  );
  return primary?.document_language;
}

const KidsOverview: FC = () => {
  const { currentPage, isPriips } = useRaidrKids();
  const classes = useKidsOverviewStyles();

  // Create objects for storing the selected and available languages in the data
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>();

  const { data: priipsOverviewData, isPending, error } = useKIDSOverviewData();

  useEffect(() => {
    if (priipsOverviewData) {
      setSelectedLanguage(findPrimaryLanguage(priipsOverviewData));
    }
  }, [priipsOverviewData]);

  if (isPending) {
    return (
      <TableLoader
        title={`${isPriips ? 'PRIIPs KIDs overview' : 'UCITS KIIDs overview'} loading...`}
      />
    );
  }

  if (error) {
    return <ErrorBoundaryMessage />;
  }

  if (!priipsOverviewData) {
    return (
      <NoDataMessage />
    )
  }

  const languageOptions = priipsOverviewData
    ? [
        ...new Set(
          priipsOverviewData.map((value: any) => value.document_language),
        ),
      ]
    : [];

  const primaryLanguage = findPrimaryLanguage(priipsOverviewData);

  const tableData = buildKidsEditorOverviewTableData(
    priipsOverviewData,
    selectedLanguage,
  );

  const columns = buildTableColumns(
    priipsOverviewData[0].manco_id,
    selectedLanguage,
  );

  return (
    <GridItem xs={12} card>
      <div className={classes.headerContainer}>
        <div className={classes.generalSelectContainer}>
          {selectedLanguage && (
            <Tooltip
              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
              title="Switch between the different languages available for the documents."
              placement={'top'}
              arrow
              enterNextDelay={1000}
              enterDelay={1000}
            >
              <div>
                <RaptorSelect
                  options={languageOptions}
                  handleChoice={setSelectedLanguage}
                  defaultOption={selectedLanguage}
                  textColor="white"
                  backgroundColor={mainColors.mainBlue}
                  hoverColor={mainColors.mainBlue_lighter}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={classes.downloadButtonContainer}>
          {selectedLanguage && (
            <DownloadFileButton
              mancoId={
                priipsOverviewData && priipsOverviewData.length
                  ? priipsOverviewData[0].manco_id
                  : ''
              }
              tooltipMessage="Download all available documents. This may take a few moments to complete."
              documentLanguage={selectedLanguage}
            />
          )}
        </div>
      </div>
      <CustomTable<KidsOverviewTableData>
        title={isPriips ? 'PRIIPs KIDs Overview' : 'UCITS KIIDs Overview'}
        showToolbar={true}
        id={`kiids_editor_overview_table`}
        loading={priipsOverviewData && priipsOverviewData.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        detailPanel={
          isPriips
            ? KidsSecondLevelTableWrapper(
                selectedLanguage === primaryLanguage ||
                  selectedLanguage === 'Swiss English'
                  ? 'allFunctionality'
                  : 'viewOnly',
              )
            : KidsSecondLevelTableWrapper(
                currentPage === 'ucits-kiids-overview-view'
                  ? 'viewOnly'
                  : 'allFunctionality',
              )
        }
        columns={columns}
      />
    </GridItem>
  );
};

export default KidsOverview;

const KidsSecondLevelTableWrapper = (
  overviewType: 'allFunctionality' | 'editOnly' | 'viewOnly',
) => {
  return [
    (rowData: KidsOverviewTableData) => {
      const icon = () =>
        rowData.secondLevelData.length ? <UnfoldMore /> : <></>;

      const render = () => {
        switch (overviewType) {
          case 'allFunctionality':
            return (
              <KidsOverviewAllFunctionalitySecondLevelTable
                data={rowData.secondLevelData}
              />
            );
          case 'viewOnly':
            return (
              <KiidsOverviewViewOnlySecondLevelTable
                data={rowData.secondLevelData}
              />
            );
          default:
            return <div></div>;
        }
      };

      return {
        disabled: !rowData.secondLevelData.length,
        icon,
        openIcon: UnfoldLess,
        render,
        open: true,
      };
    },
  ];
};
