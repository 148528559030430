import React, { useEffect } from 'react';

import {
  allPagesSelector,
  clientConfigurationSelector,
  clientSectionsSelector,
} from '../redux/pages/selectors';
import { Section } from '../types/server-data/ClientConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../redux/auth/selectors';
import { Navigate, Route, Routes } from 'react-router-dom';
import { fetchClientConfig } from '../redux/pages/actions';
import LoggedInRoute from './LoggedInRoute';
import NotFound from './NotFound';
import LoggedOutRoute from './LoggedOutRoute';
import Dashboard from '../components/pages/dashboard/Dashboard';
import GeneralPage from '../components/general/GeneralPage';
import GeneralComponentErrorShield from '../components/general/GeneralComponentErrorShield';
import axios from 'axios';
import DocumentationViewer from '../components/documentation/DocumentationViewer.component';
import SettingsRouter from '../components/settings/SettingsRouter.component';
import Signup from '../components/pages/auth/Signup.component';
import Login from '../components/pages/auth/Login';
import ResetPassword from '../components/pages/auth/ResetPassword.component';

const Pages = () => {
  const dispatch = useDispatch();

  // in case the first page in a section isn't the overview
  const isAuthenticated = useSelector(isLoggedInSelector);
  const clientSections = useSelector(clientSectionsSelector);
  const allPages = useSelector(allPagesSelector);

  const configName = useSelector(configNameSelector);

  const clientConfig = useSelector(clientConfigurationSelector);

  const unconventionalRedirects =
    clientSections && allPages
      ? clientSections
          .map((section: Section) => {
            return (
              <Route
                key={section.sectionId}
                path={`/${section.sectionId}`} // Match the URL
                element={
                  <Navigate
                    state={{ from: section.sectionId }}
                    to={`/${section.sectionId}/${section.pages[0]}`}
                    replace
                  />
                }
              />
            );
          })
          // filter out any that just go to overview
          .filter((el) => el && true)
      : [];

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }

    return () => {
      // source.cancel('cancelling ');
    };
  }, [isAuthenticated]);

  const configError = `No client configuration for ${configName} was detected. Please contact the RiskSystem administrator.`;

  return (
    <GeneralComponentErrorShield
      dataObjects={clientConfig}
      customErrorMessage={configError}
      customLoadingMessages={['Loading your Raptor configuration...']}
    >
      <Routes>
        {/* Redirect to /dashboard */}
        <Route path="/" element={<Navigate to="/dashboard" replace />} />

        <Route
          path="/dashboard"
          element={<LoggedInRoute Component={Dashboard} />}
        />
        <Route path="/signup" element={<LoggedOutRoute Component={Signup} />} />
        <Route path="/login" element={<LoggedOutRoute Component={Login} />} />
        <Route
          path="/reset-password"
          element={<LoggedOutRoute Component={ResetPassword} />}
        />

        {/* Unconventional Redirects */}
        {unconventionalRedirects}

        <Route
          path="/settings/*"
          element={<LoggedInRoute Component={SettingsRouter} />}
        />
        <Route
          path="/documentation/*"
          element={<LoggedInRoute Component={DocumentationViewer} />}
        />
        <Route
          path="/:section/:pageId"
          element={<LoggedInRoute Component={GeneralPage} />}
        />

        {/* Catch-all route */}
        <Route path="*" element={<LoggedInRoute Component={NotFound} />} />
      </Routes>
    </GeneralComponentErrorShield>
  );
};
export default React.memo(Pages);
