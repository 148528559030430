import { FC, useEffect, useState } from 'react';
import GridItem from '../../../../layout/GridComponents/GridItem';
import CustomTable from '../../../../tables/CustomTable';
import { CustomColumn } from '../../../../../types/components/tables/tableTypes';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import PublishIcon from '@mui/icons-material/Publish';
import client from '../../../../../utilities/requestClient';

interface DelegatedActsOverviewData {
  fundId: string;
  fundIdString: string;
  fundName: string;
  lastEditTimestamp: string;
  lastEditedBy: string;
  version: string;
  numUnresolvedComments: number;
  versionNumbers: string[];
}

function buildOverviewData(data: any): DelegatedActsOverviewData[] {
  if (!data) return [];
  const tableData: DelegatedActsOverviewData[] = [];
  data.forEach((element: any) => {
    tableData.push({
      fundId: element.fund_id,
      fundIdString: element.fund_id_string,
      fundName: element.fund_name,
      lastEditTimestamp: element.last_edit_timestamp,
      lastEditedBy: element.last_edited_by_name,
      version: element.version,
      numUnresolvedComments: element.num_unresolved_comments,
      versionNumbers: JSON.parse(element.version_numbers),
    });
  });
  return tableData;
}

function buildOverviewColumns(): CustomColumn<DelegatedActsOverviewData>[] {
  const navigate = useNavigate();
  const columns: CustomColumn<DelegatedActsOverviewData>[] = [
    {
      title: 'Fund ID',
      field: 'fundIdString',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Last Edited Timestamp',
      field: 'lastEditTimestamp',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: DelegatedActsOverviewData) =>
        rowData.lastEditTimestamp.substring(0, 16).replace('T', ' '),
    },
    {
      title: 'Last Edited By',
      field: 'lastEditedBy',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Version',
      field: 'version',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Edit',
      field: 'edit',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: DelegatedActsOverviewData) => (
        <Button
          variant="contained"
          onClick={() => {
            navigate(
              `delegated-acts-editor?fundId=${rowData.fundId}&fundCode=${rowData.fundIdString}&fundName=${rowData.fundName}`,
            );
          }}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      ),
    },
    // {
    //     title: 'Review',
    //     field: 'review',
    //     cellStyle: {
    //         textAlign: 'center',
    //     },
    //     headerStyle: { textAlign: 'center' },
    //     render: (rowData: DelegatedActsOverviewData) => (
    //         <Button
    //             variant="contained"
    //             onClick={() => {
    //                 return 0;
    //             }
    //             }
    //             startIcon={<PreviewIcon />}
    //         >
    //             Review
    //         </Button>
    //     )
    // },
    {
      title: 'Publish',
      field: 'publish',
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: { textAlign: 'center' },
      render: (rowData: DelegatedActsOverviewData) => (
        <Button
          variant="contained"
          onClick={() => {
            return 0;
          }}
          startIcon={<PublishIcon />}
        >
          Publish
        </Button>
      ),
    },
  ];
  return columns;
}

const DelegatedActsOverview: FC = (props) => {
  // Need to add this here for the moment until the routing is improved
  const requestClient = client();
  const [delegatedActsData, setDelegatedActsData] = useState<any[] | null>(
    null,
  );
  const getDelegeatedActsData = async () => {
    requestClient
      .get('get_raidr_overview_data/esg_delegated_acts')
      .then((response) => {
        setDelegatedActsData(response.data);
      });
  };
  useEffect(() => {
    getDelegeatedActsData();
  }, []);

  const tableData = buildOverviewData(delegatedActsData);
  const columns = buildOverviewColumns();

  return (
    <GridItem xs={12} card>
      <CustomTable<DelegatedActsOverviewData>
        showToolbar={true}
        id={`raidr_overview_table`}
        loading={delegatedActsData && delegatedActsData.length ? false : true}
        data={tableData}
        options={{
          paging: false,
          search: true,
          exportButton: false,
          exportAllData: false,
          emptyRowsWhenPaging: false,
        }}
        columns={columns}
      />
    </GridItem>
  );
};

export default DelegatedActsOverview;
