import FastAPIInstance from './risksystem3-public-api';
import TokenService from './token-service';
import jwt_decode from 'jwt-decode';

interface DecryptedToken {
  exp: number;
  has_multi_clients: boolean;
  has_specific_funds: boolean;
  has_specific_resources: boolean;
  ip_address: string;
  is_email_needed: boolean;
  is_qr_code_needed: boolean;
  two_factor_auth_is_valid: boolean;
  user_client_id: string;
  user_email: string;
  user_id: string;
  user_type: string;
}

const FastAPIlogin = async (username: string, password: string) => {
  const formData = new FormData();
  //add three variable to form
  formData.append('username', username);
  formData.append('password', password);
  formData.append('grant_type', 'password');

  const tokenRes = await FastAPIInstance.post(`/v1/token`, formData);
  const decryptedToken = jwt_decode(
    tokenRes.data.access_token,
  ) as DecryptedToken;

  return FastAPIInstance
    .get(`/v1/user/user_id/${decryptedToken.user_id}`, {
      headers: {
        Authorization: `Bearer ${tokenRes.data.access_token}`,
      },
    })
    .then((userInfoResponse) => {
      if (userInfoResponse.status === 200) {
        TokenService.setUser({ ...tokenRes.data, ...userInfoResponse.data });
      }
      return userInfoResponse;
    })
    .catch((error) => {
      console.error(error);
    });
};

const FastAPIlogin2fa = async (
  accessToken: string | null,
  authKey: string | null,
  emailCode: string | null,
  qrCode: string | null,
) => {

  const formData = new FormData();
  if (authKey) {
    formData.append('auth_key', authKey);
  }
  if (emailCode) {
    formData.append('email_verification_code', emailCode);
  }
  if (qrCode) {
    formData.append('qr_code_verification_code', qrCode);
  }

  const tokenRes = await FastAPIInstance.post('/v1/verification_code', formData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const decryptedToken = jwt_decode(
    tokenRes.data.access_token,
  ) as DecryptedToken;

  return FastAPIInstance
    .get(`/v1/user/user_id/${decryptedToken.user_id}`, {
      headers: {
        Authorization: `Bearer ${tokenRes.data.access_token}`,
      },
    })
    .then((userInfoResponse) => {
      if (userInfoResponse.status === 200) {
        TokenService.setUser({ ...tokenRes.data, ...userInfoResponse.data });
      }
      return userInfoResponse;
    })
    .catch((error) => {
      console.error(error);
    });
};

const FastAPILogout = () => {
  TokenService.removeUser();
  // redirect user to login page
  // unless the user is on signup or reset-password pages
  if (
    !window.location.pathname.includes('/signup') &&
    !window.location.pathname.includes('/reset-password') &&
    !window.location.pathname.includes('/login')
  ) {
    window.location.assign('/raptor/login');
  }
};

const getCurrentUser = () => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  return user;
};

function getAuthHeader() {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return {};
  }
}

const FastAPIAuthService = {
  // signup,
  FastAPIlogin,
  FastAPIlogin2fa,
  FastAPILogout,
  getCurrentUser,
  getAuthHeader,
};

export default FastAPIAuthService;
